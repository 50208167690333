import { getMapsHelper } from './util/mapsHelper';
import { AutomatitCarousel } from './automatit_carousel.m';

export const initFacilityMobile = () => {
	document.querySelector('#toggle_fac_info').addEventListener('click', () => {
		document.querySelector('#toggle_fac_info').switchAttribute('state', 'open', 'closed');
		document.querySelector('#facility_top').switchAttribute('state', 'open', 'closed');
	});
};

export const initFacCaro = () => {
	AutomatitCarousel({
		element: document.querySelector('#facility_caro'),
		showDots: true,
		images: [
			{src: "/static/images/facility_images/rates1.jpg", alt: ""},
			{src: "/static/images/facility_images/rates2.jpg", alt: ""},
		],
		imagesAsBackgrounds: true,
		useChevrons: false
	});
};

export const initFacMap = async () => {
	const mapHelper = getMapsHelper();

	await mapHelper.ready();

	const theMap = mapHelper.createMap({
		element: document.querySelector('#facility_map'),
		locationElementSelector: '#facility_map',
		useRichmarker: true,
		markerReducer: el => {
			return {
				lat: el.getAttribute('lat'),
				lng: el.getAttribute('lng'),
				content: `<span><img src="/static/images/icons/map_pin.svg" alt="" /></span>`
			};
		}
	});

	// eslint-disable-next-line no-undef
	google.maps.event.addListenerOnce(theMap, 'idle', () => {
		theMap.setZoom(15);
	});

	const mapToggle = document.querySelector('#toggle_fac_map');

	mapToggle.addEventListener('click', () => {
		mapToggle.switchAttribute('state', 'off', 'on');
		document.querySelector('#facility_map').switchAttribute('state', 'hide', 'show');
	});
};

export const initFacilityFilters = () => {

	const filters = document.querySelectorAll('#facility_filters button');
	const rows = document.querySelectorAll('.rates_row');
	const noUnits = document.querySelector('#no_units_message');

	document.querySelector('#facility_filters').addEventListener('click', e => {
		const clickedFilter = e.target.closest('button');

		if(clickedFilter) { 
			if(clickedFilter.hasAttribute('active')) {
				clickedFilter.removeAttribute('active');
			} else {
				filters.forEach(filter => filter.removeAttribute('active'));
				clickedFilter.setAttribute('active', true);
			}

			const activeFilter = document.querySelector('#facility_filters button[active]');

			let numUnits = 0;

			rows.forEach(row => {
				row.setAttribute('hide', true);

				let matches = {
					size: false,
					filter: false,
				};

				

				if(activeFilter) {
					if(row.getAttribute('size') === activeFilter.getAttribute('size') || activeFilter.getAttribute('size') === "all") {
						matches.size = true;
					}
					if(row.getAttribute('filter') === activeFilter.getAttribute('filter') || activeFilter.getAttribute('filter') === "all") {
						matches.filter = true;
					}
				} else {
					matches.size = true;
					matches.filter = true;
				}

				if(matches.size && matches.filter) {
					row.removeAttribute('hide');
					numUnits++;
				}
			});

			if(numUnits) {
				noUnits.style.display = 'none';
			} else {
				noUnits.style.display = 'block';
			}
		}


	});
};